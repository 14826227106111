import { createBrowserRouter } from 'react-router-dom'

import Login from 'src/Pages/Login'
import LoginLayout from './Layout/LoginLayout'
import ForgotPassword from './Pages/ForgotPassword'
import NotFoundPage from './Pages/NotFoundPage'
import Dashboard from './Pages/Dashboard'
import DashboardLayout from './Layout/DashboardLayout'
import { routeUrls } from './utils/constant'
import BusinessDirectory from './Pages/BusinessDirectory'
import Dashboard404 from './Pages/Dashboard404'
import ViewBusiness from './Pages/BusinessDirectory/ViewBusiness'
import Users from './Pages/Users'
import Management from './Pages/Management'
import HouseRequests from './Pages/HouseRequests'
import ViewHouseRequest from './Pages/HouseRequests/ViewHouseRequest'
import Setting from './Pages/Setting'
import CustomerSupport from './Pages/CustomerSupport'
import ViewCustomerSupportRequest from './Pages/CustomerSupport/ViewCustomerSupportRequest'
import ResetPassword from './Pages/ResetPassword'
import Notification from './Pages/Notification'
import ReportedHouse from './Pages/ReportedHouse'
import ViewReportedHouse from './Pages/ReportedHouse/ViewReportedHouse'
import ContactUs from './Pages/ContactUs'

const router = createBrowserRouter([
  {
    // Public routes
    path: '/',
    element: <LoginLayout />,
    errorElement: <NotFoundPage />,
    children: [
      {
        index: true,
        element: <Login />,
      },
      {
        path: routeUrls.login,
        element: <Login />,
      },
      {
        path: routeUrls.contactUs,
        element: <ContactUs />,
      },
      {
        path: routeUrls.forgotPassword,
        element: <ForgotPassword />,
      },
      {
        path: routeUrls.resetPassword,
        element: <ResetPassword />,
      },
    ],
  },
  {
    // Private routed required Authentication
    path: routeUrls.dashboard,
    element: <DashboardLayout />,

    children: [
      {
        index: true,
        element: <Dashboard />,
      },
      {
        path: routeUrls.businessDirectory,
        element: <BusinessDirectory />,
      },
      {
        path: routeUrls.viewBusiness,
        element: <ViewBusiness />,
      },
      {
        path: routeUrls.users,
        element: <Users />,
      },
      {
        path: routeUrls.management,
        element: <Management />,
      },
      {
        path: routeUrls.houseRequests,
        element: <HouseRequests />,
      },
      {
        path: routeUrls.viewhouseRequest,
        element: <ViewHouseRequest />,
      },
      {
        path: routeUrls.customerSupport,
        element: <CustomerSupport />,
      },
      {
        path: routeUrls.viewCustomerSupportRequest,
        element: <ViewCustomerSupportRequest />,
      },
      {
        path: routeUrls.reportedHouse,
        element: <ReportedHouse />,
      },
      {
        path: routeUrls.viewReportedHouseRequest,
        element: <ViewReportedHouse />,
      },
      {
        path: routeUrls.settings,
        element: <Setting />,
      },
      {
        path: routeUrls.notification,
        element: <Notification />,
      },
      {
        path: '*',
        element: <Dashboard404 />,
      },
    ],
  },
])

export default router
